<template>
  <div class="iss-left">
    <div class="left-title">添加内容</div>
    <template v-for="item in fields" :key="item.id">
      <div
        v-if="item.children.length"
        :class="{
          'left-group': true,
          base: item.id === 'base',
          custom: item.id !== 'base',
        }"
      >
        <div class="group-title">{{ item.group }}</div>
        <div
          v-for="child in item.children"
          :key="child.id"
          :class="{ 'group-item': true, 'item-disabled': child.disabled }"
          @click="handleClick(child)"
        >
          <a-avatar size="small" class="item-icon">
            <template #icon>
              <component :is="icons[child.fieldIcon]" />
            </template>
          </a-avatar>
          <span>{{ child.fieldName }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Avatar } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import { watch, ref } from 'vue';
import { useStore } from 'vuex';
import { randoms } from '@/utils';
// import fieldApi from '@/api/field';
import { listData } from './list.json';

export default {
  name: 'fields',
  components: {
    AAvatar: Avatar,
  },
  setup() {
    const store = useStore();
    const fields = ref([]);
    fields.value = listData;
    const setBaseFields = () => {
      const questionnaireFields = store.state.common.questionnaireFields;
      const temp = questionnaireFields.map(i => i.fieldCode);
      fields.value.forEach(item => {
        item.id === 'base' &&
          item.children.forEach(child => {
            child.disabled = temp.includes(child.fieldCode);
          });
      });
    };
    setBaseFields();

    watch(() => store.state.common.questionnaireFields, setBaseFields, {
      deep: true,
    });

    return {
      listData,
      fields,
      icons,
      setBaseFields,
      handleClick: data => {
        // console.log('data', data)
        const temp = { ...data };
        // todo id 暂时写死
        const id = '1130876015006978049';

        if (temp.fieldCode) {
          temp.formId = id;
        } else {
          temp.fieldCode = randoms(4);
          temp.questionnaireId = id;
          temp.questionAnswerVoList = [];
          temp.id = randoms(4);
        }
        if (temp.fieldName === '市') {
          if (
            store.state.common.questionnaireFields.filter(
              i => i.fieldName === '省'
            ).length == 0
          ) {
            store.state.common.questionnaireFields.push({
              ...fields.value[0].children.filter(i => i.fieldName === '省')[0],
              formId: id,
            });
          }
        }
        // console.log('temp', temp);
        store.state.common.questionnaireFields.push(temp);
      },
    };
  },
};
</script>

<style scoped lang="less">
.iss-left {
  padding: 10px;
  .left-title {
    font-size: 20px;
    font-weight: 500;
  }
  .left-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .group-title {
      width: 100%;
      padding: 14px 0 10px 0;
      font-size: 16px;
    }
    .group-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding: 8px;
      width: 236px;
      background-color: @primary-3;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      .item-icon {
        margin: 0 8px 0 3px;
        color: #fff;
        background-color: @primary-color;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        box-shadow: 0px 0px 10px 0px @primary-4;
      }
      &.item-disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
    &.custom .group-item:nth-last-of-type(1) {
      margin: 0;
    }
    &.base .group-item {
      width: 112px;
      color: #fff;
      background: linear-gradient(135deg, @primary-5 0%, @primary-color 100%);
      .item-icon {
        color: @primary-color;
        background-color: #fff;
      }
    }
  }
}
</style>
